






































































import axios from 'axios';
import gql from 'graphql-tag';
import _ from 'lodash';
import * as math from 'mathjs';
import moment from 'moment';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import UiSelect2 from '@/components/ui/UiSelect2.vue';
import { InactivityWatcher } from '@/inactivityWatcher';
import { MUT_SNACKBAR } from '@/store';
import { getSymbolForCurrency, numberWithCommas } from '@/utils/coinUtils';
import { clearDownloadIframes, downloadAuthorizedFile } from '@/utils/downloadFile';
import { requestParentToKeepActive } from '@/utils/iframeMessageRequester';

import { BaseVue } from '../../BaseVue';
import UiButton from '../ui/UiButton.vue';
import UiDataTable from '../ui/UiDataTable.vue';
import UiDatePicker from '../ui/UiDatePicker.vue';

interface AvailableTokenResponse {
  availableTokens: string[];
}

@Component({
  apollo: {
    availableTokens: {
      query: gql`
        query getAvailableTokens($orgId: ID!) {
          availableTokens(orgId: $orgId)
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      update: (data: AvailableTokenResponse) => {
        // [{ id: '1', name: 'test' }]
        const sorted = _.sortBy(data.availableTokens, (m) => m);
        const items = sorted.map((m) => {
          return {
            id: m,
            name: m,
          };
        });
        return items;
      },
      loadingKey: 'isLoadingTokens',
    },
    historicPrices: {
      query: gql`
        query getHistoricPrice(
          $orgId: ID!
          $coin: String!
          $fromTimestampSec: Int
          $toTimestampSec: Int
          $pageSize: Int
          $pageToken: String
        ) {
          historicPrices(
            orgId: $orgId
            coin: $coin
            fromTimestampSec: $fromTimestampSec
            toTimestampSec: $toTimestampSec
            pageSize: $pageSize
            pageToken: $pageToken
          ) {
            hasMore
            nextPageToken
            prices {
              ... on HistoricPriceSuccess {
                timestampSEC
                status
                price {
                  type
                  ... on PriceDetailCandlestick {
                    price
                    open
                    close
                    high
                    low
                    volume
                  }
                  ... on PriceDetailOverride {
                    price
                  }

                  ... on PriceDetailCoarseGrain {
                    price
                  }
                }
                steps {
                  detail
                  description
                  source
                  type
                }
              }
              ... on HistoricPriceFailure {
                timestampSEC
                status
                steps {
                  detail
                  description
                  source
                  type
                }
              }
            }
          }
        }
      `,
      variables() {
        const vars = {
          orgId: this.$store.state.currentOrg.id,
          coin: this.token,
          fromTimestampSec: this.dateFrom ? moment(this.dateFrom).startOf('day').unix() : undefined,
          toTimestampSec: this.dateTo ? moment(this.dateTo).endOf('day').unix() : undefined,
          pageSize: this.pagination.rowsPerPage,
        };
        return vars;
      },
      skip() {
        return !this.token;
      },
      result(data) {
        if (data && data.data && this) {
          this.nextPageToken = data.data.historicPrices.nextPageToken ?? null;
          this.hasMore = data.data.historicPrices.hasMore;
          this.historicPricing = data.data.historicPrices.prices.map((el: any, index: number) =>
            Object.assign({ ...el, id: index.toString() }, {})
          );
          this.pagination.totalItems = this.historicPricing.length;
        }
      },
      error(e) {
        this.showErrorSnackbar('Error loading pricing history: ' + e.message ?? '');
      },
      loadingKey: 'isLoading',
    },
  },
  components: {
    UiSelect2,
    UiDatePicker,
    UiDataTable,
    UiButton,
  },
})
export default class PricingTable extends BaseVue {
  //  init apollo query props
  historicPrices = {};
  historicPricing = [];
  availableTokens = [];

  pagination = {
    page: 1,
    rowsPerPage: 25,
    totalItems: 0,
  };

  public baseUrl = process.env.VUE_APP_API_URL;
  public isExportLoading = false;

  //  constants
  readonly headers = [
    {
      id: 'time',
      label: 'Time',
      defaultVisibility: true,
    },
    {
      id: 'price',
      label: 'Price',
      defaultVisibility: true,
      selector: (item: any) => (item.price ? this.toCurrency(item.price.price) : ''),
    },
    {
      id: 'openPrice',
      label: 'Open',
      defaultVisibility: true,
      selector: (item: any) => (item.price ? this.toCurrency(item.price.open) : ''),
    },
    {
      id: 'closePrice',
      label: 'Close',
      defaultVisibility: true,
      selector: (item: any) => (item.price ? this.toCurrency(item.price.close) : ''),
    },
    {
      id: 'lowPrice',
      label: 'Low',
      defaultVisibility: true,
      selector: (item: any) => (item.price ? this.toCurrency(item.price.low) : ''),
    },
    {
      id: 'highPrice',
      label: 'High',
      defaultVisibility: true,
      selector: (item: any) => (item.price ? this.toCurrency(item.price.high) : ''),
    },
    {
      id: 'methodology',
      label: 'Methodology',
      defaultVisibility: true,
      selector: (item: any) => {
        if (!item.price) return '';
        const { price, open, close, high, low } = item.price;
        const closePrice = Math.min(
          Math.abs(open - price),
          Math.abs(close - price),
          Math.abs(high - price),
          Math.abs(low - price)
        );
        if (closePrice === Math.abs(open - price)) return 'Open';
        if (closePrice === Math.abs(close - price)) return 'Close';
        if (closePrice === Math.abs(high - price)) return 'High';
        if (closePrice === Math.abs(low - price)) return 'Low';
        return '';
      },
    },
    {
      id: 'status',
      label: 'Status',
      defaultVisibility: true,
      selector: (item: any) => item.status.match(/[A-Z][a-z]+/g).join(' '),
    },
  ];

  // Sequence Status Source Description Details
  readonly stepHeaders = [
    {
      id: 'sequence',
      label: 'Sequence',
      defaultVisibility: true,
      selector: (item: any, index: number) => index + 1,
    },
    {
      id: 'status',
      label: 'Status',
      defaultVisibility: true,
      selector: (item: any) => (item.type === 'FallThrough' ? 'Failed' : item.type === 'Match' ? 'Succeeded' : ''),
    },
    {
      id: 'source',
      label: 'Source',
      defaultVisibility: true,
      selector: (item: any) => item.source,
    },
    {
      id: 'description',
      label: 'Description',
      defaultVisibility: true,
      selector: (item: any) => item.description,
    },
    {
      id: 'details',
      label: 'Details',
      defaultVisibility: true,
      selector: (item: any) => item.detail ?? '',
    },
  ];

  isLoading = 0;
  isLoadingTokens = 0;

  // Filtering
  fromShow = false;
  toShow = false;
  dateFrom = '';
  dateTo = this.getOrgMoment().local().subtract(2, 'day').toDate(); // Using 2 days because the date picker isn't time zone aware
  token = null;

  isExportEnabled = this.token != null && (this.dateFrom || this.dateTo);

  //  Pagination
  nextPageToken = null;
  hasMore = false;

  // Expanded Row
  expandedPricing: any = null;

  onDateChanged() {
    this.pagination.page = 1;
  }

  async onExport() {
    this.isExportLoading = true;

    requestParentToKeepActive('report', true);
    const inactivityWatcherKeepActive = InactivityWatcher.instance?.keepActive(
      () => this.isExportLoading && document.contains(this.$el)
    );
    try {
      const exportUrl = this.baseUrl + `orgs/${this.$store.state.currentOrg.id}/pricing-report`;

      const body = {
        ticker: this.token,
        startTimeSec: this.dateFrom ? moment(this.dateFrom).startOf('day').unix() : undefined,
        endTimeSec: this.dateTo ? moment(this.dateTo).endOf('day').unix() : undefined,
      };

      const resp = await axios({ method: 'post', url: exportUrl, data: body, withCredentials: true });

      clearDownloadIframes();
      if (resp.status === 200) {
        const exportCsvUrls = resp.data;
        exportCsvUrls?.forEach((url: any) => {
          downloadAuthorizedFile(url);
        });
      } else {
        this.showErrorMessage();
      }
    } finally {
      this.isExportLoading = false;
      requestParentToKeepActive('report', false);
      inactivityWatcherKeepActive?.dispose();
    }
  }

  showErrorMessage() {
    this.$store.commit(MUT_SNACKBAR, {
      color: 'error',
      message: 'Failed to export. Try again later',
    });
  }

  fetchMore() {
    this.$apollo.queries.historicPrices
      .fetchMore({
        variables: {
          orgId: this.$store.state.currentOrg.id,
          coin: this.token,
          pageSize: this.pagination.rowsPerPage,
          pageToken: this.nextPageToken,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const newPrices = fetchMoreResult.historicPrices.prices;
          const nextPageToken = fetchMoreResult.historicPrices.nextPageToken;
          const hasMore = fetchMoreResult.historicPrices.hasMore;

          return {
            historicPrices: {
              __typename: prevResult.historicPrices.__typename,
              prices: [...prevResult.historicPrices.prices, ...newPrices],
              hasMore,
              nextPageToken,
            },
          };
        },
      })
      .then(() => {
        this.pagination.totalItems = this.historicPricing.length;
        this.pagination.page += 1;
      });
  }

  toCurrency(amountStr: string) {
    if (!amountStr) {
      return '0.00';
    }
    const n = Number(amountStr);

    if (isNaN(n)) {
      return '0.00';
    }

    const v = math.format(n, { notation: 'fixed', precision: 4 });
    return `${getSymbolForCurrency(this.$store.state.currentOrg.baseCurrency)}${numberWithCommas(v)}`;
  }

  get isLastPage() {
    return this.pagination.page * this.pagination.rowsPerPage >= this.historicPricing.length;
  }

  get isFirstPage() {
    return this.pagination.page === 1;
  }

  get pageStartItem() {
    const startItem = this.pagination.rowsPerPage * (this.pagination.page - 1) + 1;
    return startItem;
  }

  get pageEndItem() {
    const endItem = this.pagination.rowsPerPage * this.pagination.page;
    return Math.min(endItem, this.pagination.totalItems);
  }

  get exportEnabled() {
    return this.token != null && (this.dateFrom || this.dateTo);
  }

  clickNext() {
    if (!this.isLastPage) {
      this.pagination.page += 1;
    } else if (this.isLastPage && this.hasMore) {
      this.fetchMore();
    }
  }

  clickPrevious() {
    if (!this.isFirstPage) {
      this.pagination.page -= 1;
    }
  }

  toggleRowView({ item }: { item: any; row: HTMLElement }) {
    if (this.expandedPricing && this.expandedPricing.id === item.id) {
      this.expandedPricing = null;
    } else {
      this.expandedPricing = item;
    }
  }

  @Watch('token')
  onTokenChange() {
    this.pagination.page = 1;
  }
}
